import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { menuItems } from '../constant/menuItemsData';

const MenuTabs = () => {
  const [selectedCategory, setSelectedCategory] = useState(menuItems[0].title);

  const handleCategoryClick = (title) => {
    setSelectedCategory(title);
  };

  const currentCategory = menuItems.find(category => category.title === selectedCategory);

  return (
    <div className="menu" id="menus">
      <Container fluid >
        <div className="row" >
        {/* <div classname="title-container">
        <h2 >Our Menu</h2>
        </div> */}
        <div className="title-container">
          <h2 className="menutext-center mb-4">
            Our Menu
          </h2>
        </div>
        <Col md={3} className="sidebar">
          <div className="menucategory" >
            {menuItems.map(category => (
              
              <div
              key={category.title}
              className={`menu-item ${category.title === selectedCategory ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.title)}
            >
                <div className="sidebarImagesContainer"> <img src={category.imageUrl} className="sidebarImages"/></div>

                {category.title}
                
              </div>
            ))}
          </div>
        </Col>
        <div className="col-md-9" >
          <div className="row">
          {currentCategory && currentCategory.products.map((product, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-3">
                <div className="cardContainer">
                <Card className="product-card">
                  {/* <Card.Img variant="top" src={currentCategory.imageUrl} /> */}
                  <Card.Body>
                    <Card.Title>{product}</Card.Title>
                    <Card.Text>
                      Price: {currentCategory.rate[index]}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </div>
                
              </Col>
            ))}
          </div>
        </div>
        </div>
    
    </Container>
    </div>
    
  );
};

export default MenuTabs;
