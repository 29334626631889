import React from "react";
import { Carousel, Col } from "react-bootstrap";
import sallad from "../assets/img/sallad.png";
import shawarma from "../assets/img/shawa.png";
import PLATTER from "../assets/img/platters.png";

const AnimatedSlider = () => {
  const whatsappNumber = "1234567890"; 
  const message =
    "Hello! I would like to inquire about your Shawarma franchise."; 
  return (
    <div className="courContainer">
      <div className="row">
        {/* First Column for Text */}
        <div className="carol">
        <div className="col-md-6">
          <div className="firscol6" >
            <h1 className="display-4 mb-4"style={{  color: '#00',  fontWeight:"700"}}>
              Your Shawarma Craving Ends Here...
            </h1>
            <p className="lead mb-4" style={{fontWeight:"400" }}>
              Launched in 2019, Arbab's Shawarma is set to revolutionize the
              franchise industry. Join hands with Arbab's Shawarma and become
              our valued <strong>Arbab's SHAWARMA PARTNER</strong>.
            </p>
            <a
              href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                message
              )}`}
              className="btn "
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Inquiry
            </a>
          </div>
        </div>

        {/* Second Column for Carousel */}
        <Col md={6}>
          <Carousel controls={false} indicators={false}>
            <div className="carousel-item">
              <img className="carousel-img  " src={sallad} alt="First slide" />
            </div>
            <div className="carousel-item">
              <img className="carousel-img  " src={shawarma} alt="Second slide" />
            </div>

            <Carousel.Item>
              <img
                className=" carousel-img "
                src={PLATTER}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        </div>
      </div>
    </div>
  );
};

export default AnimatedSlider;
