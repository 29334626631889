import shawarma from "../assets/img/shawarma.png"
import fries from "../assets/img/fries.png"
import wing from "../assets/img/chicken-wings.png"
import BURGER from "../assets/img/burger.png"
import MEAL from "../assets/img/meal.png"
import PLATTER from "../assets/img/platter.png"
import BUY3 from "../assets/img/buy.png"
import SALAD from "../assets/img/salad.png"
import DRINK from "../assets/img/drinks.png"
export const menuItems = [
  {
    title: "SHAWARMA",
    imageUrl: shawarma,
    products: [
      "BASIC SHAWARMA",
      "CLASSIC CHICKEN SHAWARMA",
      "PERI PERI SHAWARMA",
      "MAKHANI SHAWARMA",
      "RUMALI SHAWARMA",
      "CHIPOTLE CHICKEN SHAWARMA",
      "TANDOORI CHICKEN SHAWARMA",
      "ARABIC SPECIAL SHAWARMA",
      "SPICY SHAWARMA",
      "CHEESE CHICKEN SHAWARMA",
      "CHICKEN TIKKA SHAWARMA",
      "DOUBLE CHEESE SHAWARMA",
      "BBQ CHICKEN SHAWARMA",
      "FIRANGI SPECIAL SHAWARMA",
      "MEXICAN SHAWARMA",
      "CHEESE BURST SHAWARMA",
      "CHEESY FUSION SHAWARMA",
    ],
    rate: [
      " 50 /- (only one size)",
      "6-Inch: 70/-,8-Inch:125/-,113/-",
      "6-Inch: 80/-,8-Inch: 150/-,113/-",
      "6-Inch: 80/-,8-Inch: 138/-,113/-",
      "6-Inch: 90/-,8-Inch: 150/-",
      "6-Inch: 90/-,8-Inch: 150/-,138/-",
      "6-Inch: 90/-,8-Inch: 150/-,138/-",
      "6-Inch: 100/-,8-Inch: 175/-,138/-",
      "6-Inch: 100/-,8-Inch: 175/-,138/-",
      "6-Inch: 100/-,8-Inch: 175/-,138/-",
      "6-Inch: 100/-,8-Inch: 163/-,138/-",
      "6-Inch: 120/-,8-Inch: 188/-,163/-",
      "6-Inch: 120/-,8-Inch: 188/-,175/-",
      "6-Inch: 120/-,8-Inch: 200/-,163/-",
      "6-Inch: 120/-,8-Inch: 225/-,188/-",
      "6-Inch: 130/-,8-Inch: 200/-,163/-",
      "6-Inch: 140/-,8-Inch: 225/-,188/-",
    ],
    isActive: true,
  },

  {
    title: "FRIES",
    imageUrl: fries,
    products: [
      "SALTED FRIES",
      "PERI PERI FRIES",
      "MAGIC MASALA FRIES",
      "CHEESY FRIES",
      "CHILLY GARLIC FRIES",
      "MEXICAN FRIES",
      "BBQ FRIES",
      "FIRANGI FRIES",
      "CHIPOTLE FRIES",
      "OVERLOADED FRIES",
    ],
    rate: [
      "100/-,163/-",
      "125/-,175/-",
      "125/-,175/-",
      "150/-,200/-",
      "163/-,213/-",
      "163/-,213/-",
      "163/-,213/-",
      "163/-,213/-",
      "163/-,213/-",
      "175/-,225/-",
    ],
    isActive: false,
  },
  {
    title: "WINGS",
    imageUrl: wing,
    products: [
      "CHICKEN WINGS",
      "PERI PERI WINGS",
      "CAJUN SPICE WINGS",
      "BBQ WINGS",
      "CHIPOTLE WINGS",
      "CHEESE WINGS",
      "SWEET CHILLI WINGS",
      "KOREAN SPICY WINGS",
    ],
    rate: [
      "188/-",
      "213/-",
      "238/-",
      "275/-",
      "275/-",
      "275/-",
      "288/-",
      "288/-",
    ],
    isActive: false,
  },
  {
    title: "BURGER",
    imageUrl: BURGER,
    products: [
      "CHICKEN BURGER",
      "XINGER BURGER",
      "GRILLED BURGER",
      "WHOLE CHICKEN BURGER",
      "PERI PERI BURGER,SPICY BURGER",
      "BBQ BURGER",
    ],
    rate: [
      "88/-,WITH CHEESE 113/-",
      "125/-,WITH CHEESE 150/-",
      "131/-WITH CHEESE 156/-",
      "163/-,WITH CHEESE 188/-",
      "163/-,WITH CHEESE 188/-",
      "169/-,WITH CHEESE 194/-",
      "169/-,WITH CHEESE 194/-",
    ],
    isActive: false,
  },

  {
    title: "MEAL",
    imageUrl: MEAL,
    products: [
      "1 CLASSIC SHAWARMA,1 SALTED FRIES,1 COLD DRINK",
      "1 CLASSIC SHAWARMA,1 PERI PERI SHAWARMA,1 SALTED FRIES,1 COLD DRINK",
      "1 CLASSIC SHAWARMA,1 MAKAHANI SHAWARMA,1 SALTED FRIES,1 COLD DRINK",
      "2 PERI PERI SHAWARMA,1 SALTED FRIES,1 COLD DRINK",
      "2 CHEESE SHAWARMA,1 SALTED FRIES,1 COLD DRINK",
      "CHEESE CHIPOTLE PLATTER,2 CLASSIC SHAWARMA,2 COLD DRINK",
      "2 CLASSIC SHAWARMA,1 PERI PERI SHAWARMA,1 MAKHANI,2 SALTED FRIES,2 COLD DRINK",
    ],
    rate: [
        "188/-", 
        "275/-", 
        "275/-", 
        "300/-", 
        "338/-", 
        "400/-", 
        "575/-",
    ],
    isActive: false,
  },
  {
    title: "SHAWARMA PLATTER",
    imageUrl: PLATTER,
    products: [
      "ARABIC PLATTER",
      "PERI PERI PLATTER",
      "CHEESE CHIPOTLE PLATTER",
      "SMOKEY BBQ PLATTER",
      "DOUBLE CHEESE PLATTER",
      "MEXICAN CHICKEN PLATTER",
      "FIRANGI SPECIAL PLATTER",
    ],
    rate: [
      "263/-",
      "288/-",
      "288/-",
      "288/-",
      "375/-",
      "375/-",
      "375/-",
    ],
    isActive: false,
  },
  {
    title: "OFFER BUY 3",
    imageUrl: BUY3,
    products: [
        "CLASSIC SHAWARMA",
        "ARABIC SHAWARMA",
        "TANDOORI CHICKEN SHAWARMA",
        "SPICY CHICKEN SHAWARMA",
        "CHEESE CHICKEN SHAWARMA",
        "BBQ CHICKEN SHAWARMA",
        "FIRANGI CHICKEN SHAWARMA",
    ],
    rate: [
        "250/-",
        "313/-",
        "313/-",
        "313/-",
        "338/-",
        "338/-",
        "375/-",
    ],
    isActive: false,
  },
  {
    title: "CHICKEN SALAD",
    imageUrl: SALAD,
    products: [
      "DIET SALAD WITH LOW FAT SAUCE",
      "CLASSIC CHICKEN SALAD",
      "PERI PERI CHICKEN SALAD",
      "ARABIC CHICKEN SALAD",
      "MEXICAN CHICKEN SALAD",
      "PREMIUM SALAD",
      "FIRANGI CHICKEN SALAD",
      "BBQ CHICKEN SALAD",
      "TANDOORI CHICKEN SALAD",
      "CHICKEN SALAMI SALAD",
    ],
    rate: [
      "188/-",
      "200/-",
      "200/-",
      "200/-",
      "200/-",
      "213/-",
      "225/-",
      "225/-",
      "225/-",
      "225/-",
    ],
    isActive: false,
  },
  {
    title: "BEVERAGE",
    imageUrl: DRINK,
    products: [
        "WATER BOTTLE", 
        "COLD DRINK", 
        "MOJITO",
    ],
    rate: [
        "25/-",
        "25/-",
        "113/-",
    ],
    isActive: false,
  },
];
