import React from "react";
import shawarma from "../assets/img/shawarma-icon.png";

const AboutUs = () => {
  return (
    <section className="about-us" id="about-us">
      <div className="title-container">
        <h2 className="section-title">
          ABOUT US
          {/* <div className="title-underline"></div> */}
        </h2>
      </div>

      <div className="container">
        <div className="content-container">
          <div className="col-md-6 ">
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                We serve unique Indo-Lebanese shawarmas with fresh flavors,
                attractive branding, convenient packaging, and value price
                points.
              </p>
            </div>
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                Shawarmaji, a quick-service restaurant serving Indo-Lebanese
                cuisine.
              </p>
            </div>

            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                PERPETUAL VISION LLP group has a licensed trademark of
                Shawarmaji.
              </p>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                The model delivers high quality, freshly made, and value for
                money Indo-Lebanese food through 25+ outlets operational in
                Thane, Mumbai, Navi Mumbai, and Pune.
              </p>
            </div>
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                Online presence and collaborations with Swiggy, Zomato. It helps
                Shawarmaji to enter a new business vertical, which will lead to
                an increase in revenues and profitability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
