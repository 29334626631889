
import React from 'react';

const CategoryCard = ({ title, imageUrl, products, isActive }) => {
  return (
    <div className={`categoryCard ${isActive ? 'active-card' : ''}`} style={{ minWidth: '200px', margin: '10px 28px' }}>
      <div className="card-body text-center">
        <img src={imageUrl} className="card-img-top" alt={title} style={{ width: '100px', marginBottom: '10px' }} />
        <h5 className="card-title">{title}</h5>
        {/* <ul className="list-unstyled">
          {products.map((product, index) => (
            <li key={index}>{product}</li>
          ))}
        </ul> */}
        <p className="card-text">{products.length} products</p>
      </div>
    </div>
  );
};

export default CategoryCard;
