import React from "react";
import shop from "../assets/img/shop.png"

const GoogleForm = () => {
  return (
    <div className="form" id="franchiseform">
      <div className="form text-center pt-3">
        <h2>BECOME A PARTNER</h2>
      </div>

      <h3 className="subtitle text-center pb-3">Franchise Inquiry Form</h3>

      <div className="formcontainer">
        <div className="row g-0">
          <div className="formcontain">
        <div className="col-md-6">
          <div className="formimg-fluid">
            <img src={shop} alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSc-5fGmv-6rEZWvm7D5oy-QHy61x2zI9XeXoQJ0tIDNHsW8vA/viewform?embedded=true"
            width="640"
            height="640px"
            title="Google Form"
            className="iframe-container"
          ></iframe>
        </div>
        </div>
        </div>
      </div>
      </div>
    // </section>
  );
};

export default GoogleForm;
