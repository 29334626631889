import React from "react";
import Header from "./components/Header";
import CategoryContainer from "./components/CategoryContainer";
import AboutUs from "./components/AboutUs";
import GrowthStory from "./components/GrowthStory";
import CarouselComponent from "./components/Carousel";
import MenuItem from "./components/Menu";
import Footer from "./components/Footer";
import Form from "./components/Form";
import { menuItems } from "../src/constant/menuItemsData";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  return (
    <>
      <Header />
      <CarouselComponent />
      <CategoryContainer menuItems={menuItems}/>
      <AboutUs />
      <GrowthStory />
      <MenuItem />
      <Form/>
      <Footer />
    </>
  );
};

export default App;
