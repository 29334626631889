import React, { useState } from "react";

const GrowthStory = () => {
  const [openYear, setOpenYear] = useState(null);

  const toggleDropdown = (year) => {
    setOpenYear(openYear === year ? null : year);
  };
  return (
    <section className="growth-story">
      <div className="container">
        <div className="title-container">
          <h2 className="text-yellow">
            GROWTH STORY
          </h2>
        </div>
        <div className="row">
            <p className="outlet-info">
              25+ Outlets in Thane, Mumbai, Navi Mumbai & Pune
            </p>
            <div className="timeline">
              {/* Year 2019 */}
              <div className="year-block">
                <div
                  className="year-header"
                  onClick={() => toggleDropdown(2019)}
                >
                 <span className="text-yellow"> Year: 2019</span>  2 Outlets
                </div>
                {openYear === 2019 && (
                  <div className="milestones">
                    <div className="milestone">
                      <h3>MARCH</h3>
                      <p>Startup</p>
                      <p>FOOD TRUCK</p>
                    </div>
                    <div className="milestone">
                      <h3>JULY</h3>
                      <p>OUTLET NO. 1</p>
                      <p>VASANT VIHAR, THANE</p>
                    </div>
                    <div className="milestone">
                      <h3>DECEMBER</h3>
                      <p>OUTLET NO. 2</p>
                      <p>HARINIWAS CIRCLE, THANE</p>
                    </div>
                  </div>
                )}
              </div>
              {/* Year 2021 */}
              <div className="year-block">
                <div
                  className="year-header"
                  onClick={() => toggleDropdown(2021)}
                >
                  <span className="text-yellow">Year: 2021 </span><span>2 Outlets, 4 Franchises</span>
                </div>
                {openYear === 2021 && (
                  <div className="milestones">
                    <div className="milestone">
                      <h3>MARCH</h3>
                      <p>Startup</p>
                      <p>FOOD TRUCK</p>
                    </div>
                    <div className="milestone">
                      <h3>JULY</h3>
                      <p>OUTLET NO. 1</p>
                      <p>VASANT VIHAR, THANE</p>
                    </div>
                    <div className="milestone">
                      <h3>DECEMBER</h3>
                      <p>OUTLET NO. 2</p>
                      <p>HARINIWAS CIRCLE, THANE</p>
                    </div>
                  </div>
                )}
              </div>
              {/* Year 2022 */}
              <div className="year-block">
                <div
                  className="year-header"
                  onClick={() => toggleDropdown(2022)}
                >
                 <span className="text-yellow">Year: 2022 </span> <span>5 Franchises</span>
                </div>
                {openYear === 2022 && (
                  <div className="milestones">
                    <div className="milestone">
                      <h3>MARCH</h3>
                      <p>Startup</p>
                      <p>FOOD TRUCK</p>
                    </div>
                    <div className="milestone">
                      <h3>JULY</h3>
                      <p>OUTLET NO. 1</p>
                      <p>VASANT VIHAR, THANE</p>
                    </div>
                    <div className="milestone">
                      <h3>DECEMBER</h3>
                      <p>OUTLET NO. 2</p>
                      <p>HARINIWAS CIRCLE, THANE</p>
                    </div>
                  </div>
                )}
              </div>
              {/* Year 2023 */}
              <div className="year-block">
                <div
                  className="year-header"
                  onClick={() => toggleDropdown(2023)}
                >
                  <span className="text-yellow">Year: 2023 </span> <span>11 Franchises</span>
                </div>
                {openYear === 2023 && (
                  <div className="milestones">
                    <div className="milestone">
                      <h3>MARCH</h3>
                      <p>Startup</p>
                      <p>FOOD TRUCK</p>
                    </div>
                    <div className="milestone">
                      <h3>JULY</h3>
                      <p>OUTLET NO. 1</p>
                      <p>VASANT VIHAR, THANE</p>
                    </div>
                    <div className="milestone">
                      <h3>DECEMBER</h3>
                      <p>OUTLET NO. 2</p>
                      <p>HARINIWAS CIRCLE, THANE</p>
                    </div>
                  </div>
                )}
              </div>
              {/* Year 2024 */}
              <div className="year-block">
                <div
                  className="year-header"
                  onClick={() => toggleDropdown(2024)}
                >
                  <span className="text-yellow">Year: 2024 </span> <span>5 Franchises</span>
                </div>
                {openYear === 2024 && (
                  <div className="milestones">
                    <div className="milestone">
                      <h3>MARCH</h3>
                      <p>Startup</p>
                      <p>FOOD TRUCK</p>
                    </div>
                    <div className="milestone">
                      <h3>JULY</h3>
                      <p>OUTLET NO. 1</p>
                      <p>VASANT VIHAR, THANE</p>
                    </div>
                    <div className="milestone">
                      <h3>DECEMBER</h3>
                      <p>OUTLET NO. 2</p>
                      <p>HARINIWAS CIRCLE, THANE</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
};

export default GrowthStory;
