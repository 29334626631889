/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white">
      <div className="footer-bottom ">
        <p>©All rights Reserved to Arbab's shawarma</p>
      </div>
    </footer>
  );
};

export default Footer;
