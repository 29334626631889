/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import logo from '../assets/img/arbab.png';
import pdf from '../assets/img/Ak arbab FINAL.pdf';
import { FaBars, FaTimes } from 'react-icons/fa'; 

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="#home">
          <img src={logo} alt="Company Logo" />
        </a>
      </div>

      <nav className={isMobileMenuOpen ? 'navigation mobile-active' : 'navigation'}>
        <ul>
          <li>
            <a href="#home" onClick={closeMobileMenu}>Home</a>
          </li>
          <li>
            <a href="#menus" onClick={closeMobileMenu}>Menu</a>
          </li>
          <li>
            <a href="#about-us" onClick={closeMobileMenu}>About Us</a>
          </li>
          <li>
            <a href="#franchiseform" onClick={closeMobileMenu}>Franchise Form</a>
          </li>
        </ul>
        <div className="cta-mobile">
          <a href={pdf} className="btn" onClick={closeMobileMenu}>
            Download Brochure
          </a>
        </div>
      </nav>

      <div className="cta desktop-cta">
        <a href={pdf} className="btn">
          Download Brochure
        </a>
      </div>

      <div className="mobile-menu-icon" onClick={handleMenuToggle}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </header>
  );
};

export default Header;
